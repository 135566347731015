<div class="container" align="center" id="checkWriterMainContainer">
    <form #makePaymentForm="ngForm" (ngSubmit)="previewCheck(makePaymentForm)">
        <div *ngIf="errorOccurred" style="width: 40%;">
            <div class="alert alert-danger">
                <strong>Error! </strong>{{ errorResponse.message }}
            </div>
        </div>
        <!-- Claim Number with Validations -->
        <div class="form-group" style="width: 45%;" align="left">
            <label for="claimNumber" class="font-weight-bold">Claim Number</label>
            <input type="text" class="form-control" name="claimNumber" placeholder="Claim Number" [(ngModel)]="selectedPaymentOptions.claimNumber" #claimNumber="ngModel" required minlength="5" maxlength="12" [ngClass]="{'border border-danger' : claimNumber.errors && (claimNumber.dirty || claimNumber.touched)}"
                (keyup)="onClaimNumberUpdated($event.target.value)" (blur)="onBlurClaimNumberUpdated($event.target.value)">
            <!-- (change)="populatePaymentFormFields($event.target.value)">-->
            <div *ngIf="claimNumber.errors && (claimNumber.dirty || claimNumber.touched)" class="alert alert-danger">
                Valid Claim Number is required.
            </div>
        </div>

        <!-- Payment Element Dropdown -->
        <div class="form-group" style="width: 45%;" align="left">
            <label for="payementElement" class="font-weight-bold">Payment Element</label>
            <div class="row">
                <div class="col-md-9">
                    <select name="payementElement" class="form-control" align="center" (change)="onPaymentElementSelection($event.target.value)" [(ngModel)]="this.selectedPaymentOptions.paymentElement" [ngClass]="{'border-highlight' : !paymentElementSelected && formSubmitAttempted}">
            <option [value]="null" [selected]="true" disabled="true" [hidden]="true">Select Payment Element</option>
            <!-- <option [value]="payElement" *ngFor="let payElement of paymentElements">{{payElement}}</option> -->
            <option [ngValue]="payElement" *ngFor="let payElement of paymentElements" [selected]="claimProfile.defaultPaymentElement === payElement">{{payElement}}</option>
          </select>
                </div>
                <div class="col-md-3 text-primary" *ngIf="this.loggedIndAdjuster.houseCounsel == 'N' && (this.isLossReservePaymentElementSelected || this.selectedPaymentOptions.isExpenseReserve)">
                    <label>Expense Reserve <input type="checkbox" name="isExpenseReserve" (change)="onExpenseReserveSelectionChange()" [(ngModel)]="this.selectedPaymentOptions.isExpenseReserve"></label>
                </div>
            </div>
        </div>

        <!-- 
      Payees Multi Select Drop down.
    -->
        <div class="form-group" style="width: 45%;" align="left">
            <label for="payees" class="font-weight-bold">Payees</label>
            <div class="row">
                <div class="col-md-9">
                    <ng-multiselect-dropdown [placeholder]="'Select Payees'" [data]="dropdownList" [(ngModel)]="selectedItems" [settings]="dropdownSettings" (onSelect)="onPayeesSelect($event)" (onSelectAll)="onPayeesSelectAll($event)" (onDeSelect)="onPayeeDeSelect($event)"
                        (onDeSelectAll)="onPayeeDeSelectAll()" name="payeeMultiSelect">
                    </ng-multiselect-dropdown>
                </div>
                <div class="col-md-3 text-primary">
                    Vendor <i class="fa fa-search" aria-hidden="true" style="font-size:24px;" (click)="openVendorSearchModalPopUp()"></i>
                </div>
            </div>
        </div>

        <!-- Payee Mail To Dropdown -->
        <div class="form-group" style="width: 45%;" align="left">
            <label for="payeeMailTo" class="font-weight-bold">Mail To</label>
            <select name="payeeMailTo" class="form-control" align="center" (change)="onPayeeAddressSelection($event)" [ngClass]="{'border-highlight' : !payeeAddressSelected && formSubmitAttempted}" [(ngModel)]="selectedPaymentOptions.payeeAddress" required>
        <option [value]="null" [selected]="true" disabled="true" [hidden]="true">Select Payee Address</option>
        <option [ngValue]="payeeAddress" *ngFor="let payeeAddress of payeesWithAddress">
          {{payeeAddress.name}} -
          {{payeeAddress.address.addressLine1}},
          {{payeeAddress.address.city}},
          {{payeeAddress.address.state}},
          {{payeeAddress.address.postalCode}}
        </option>
      </select>
        </div>

        <!-- Amount Text Field with validation-->
        <div class="form-group" style="width: 45%;" align="left">
            <label for="claimEstimateAmount" class="font-weight-bold">Amount</label>
            <input type="text" class="form-control" name="claimEstimateAmount" placeholder="Estimate Amount 0.00" pattern="^\d+\.\d{2}$" [ngModel]="dispatchEstimateAmount" (ngModelChange)="onEstimateAmountUpdated($event)" #claimEstimateAmount="ngModel" required maxlength="8"
                [ngClass]="{'border border-danger' : claimEstimateAmount.errors && (claimEstimateAmount.dirty || claimEstimateAmount.touched)}">
            <div *ngIf="claimEstimateAmount.errors && (claimEstimateAmount.dirty || claimEstimateAmount.touched)" class="alert alert-danger">
                Valid Claim estimate amount is required.
            </div>
        </div>

        <!-- Split Coverages -->
        <div class="form-group" style="width: 45%;" align="left">
            <div class="row">
                <div *ngIf="showCovAType" class="col-md-4" id="covADiv">
                    <label for="covA" class="font-weight-bold">Cov A</label>
                    <input type="text" class="form-control" name="covA" placeholder="Cov A Amount 0.00" [(ngModel)]="selectedPaymentOptions.covAAmount">
                </div>
                <div *ngIf="showCovBType" class="col-md-4" id="covBDiv">
                    <label for="covB" class="font-weight-bold">Cov B</label>
                    <input type="text" class="form-control" name="covB" placeholder="Cov B Amount 0.00" [(ngModel)]="selectedPaymentOptions.covBAmount">
                </div>
                <div *ngIf="showCovCType" class="col-md-4" id="covCDiv">
                    <label for="covC" class="font-weight-bold">Cov C</label>
                    <input type="text" class="form-control" name="covC" placeholder="Cov C Amount 0.00" [(ngModel)]="selectedPaymentOptions.covCAmount">
                </div>
                <div *ngIf="showCovDType" class="col-md-4" id="covDDiv">
                    <label for="covD" class="font-weight-bold">Cov D</label>
                    <input type="text" class="form-control" name="covD" placeholder="Cov D Amount 0.00" [(ngModel)]="selectedPaymentOptions.covDAmount">
                </div>
            </div>
        </div>
        <!-- PaymentType Dropdown -->
        <div class="form-group" style="width: 45%;" align="left">
            <label for="paymentType" class="font-weight-bold">Payment Type</label>
            <select name="paymentType" class="form-control" align="center" (change)="onPaymentTypeSelection($event.target.value)" [(ngModel)]="selectedPaymentOptions.paymentTypeCode" [ngClass]="{'border-highlight' : !paymentTypeSelected && formSubmitAttempted}" required>
        <option [value]="null" [selected]="true" disabled="true" [hidden]="true">Select Payment Type</option>
        <option [ngValue]="paymentType.monetaryTypeCode" *ngFor="let paymentType of paymentTypes">{{paymentType.monetaryTypeName}}</option>
      </select>
        </div>
        <!-- InPayment Of Dropdown 
    <div class="form-group" style="width: 45%;" align="left">
      <label for="inPaymentOf" class="font-weight-bold">In Payment Of</label>
      <select name="inPaymentOf" class="form-control" align="center" (change)="onInPaymentOfSelection($event.target.value)"
        [ngClass]="{'border-highlight' : !inPaymentOfSelected && formSubmitAttempted}" [(ngModel)]="selectedPaymentOptions.inPaymentOfMap"
        required>
        <option [value]="null" [selected]="true" disabled="true" [hidden]="true">Select In Payment Of</option>
        <option [ngValue]="inPaymentOfType" *ngFor="let inPaymentOfType of inPaymentOfTypes">{{inPaymentOfType.value}}</option>
      </select>
      <input *ngIf="inPaymentOfSelected" type="text" name="editableInPaymentOf" size="45" maxlength="120" class="inputOverlay"
        [(ngModel)]="selectedPaymentOptions.inPaymentOfMap.value" (keyup)="updateEditableInPaymentOf($event.target.value)" />
    </div>-->

        <!-- InPayment Of Dropdown2 -->
        <div class="form-group" style="width: 45%;" align="left">
            <label for="typeahead-inPaymentOf" class="font-weight-bold">In Payment Of</label>
            <input id="typeahead-inPaymentOf" name="typeAheadInPaymentOf" type="text" class="form-control" [(ngModel)]="inPaymentOfTypesModel" [ngbTypeahead]="search" (focus)="focus$.next($event.target.value)" (click)="click$.next($event.target.value)" placeholder="Search for in payment of options"
                (selectItem)="selectedItem($event)" (blur)="onTypeAheadInPaymentOfChanged($event.target.value)" #instance="ngbTypeahead" maxlength="120" required/>
        </div>

        <div class="form-group" style="width: 45%;" align="center">
            <div class="row">
                <div class="col-md-6">
                    <button type="button" class="btn btn-warning" style="width : 100%; background-color:lightgray; border-color: lightgray;" (click)="openMemoModalPopup()">Add Check Memo</button>
                </div>
                <div class="col-md-6">
                    <button type="button" class="btn btn-warning" style="width : 100%; background-color:lightgray; border-color: lightgray;" (click)="openNoteModalPopup()">Add Stream Note</button>
                </div>
            </div>
        </div>

        <!-- Preview Button -->
        <div class="form-group " style="width: 45%;" align="center">
            <button type="button" class="btn btn-warning  font-weight-bold" style="background-color:#FF6319; width : 40%;" (click)="onFormSubmit()" [disabled]="!makePaymentForm.form.valid">Preview</button>
        </div>
    </form>
    <div *ngIf="loading" class="loaderContainer" style="height: 100%;">
        <div class="loader loaderContainer"></div>
    </div>

</div>
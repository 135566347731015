export const environment = {
  production: true,
  envName: 'prod',
  appVersion: "2.0.42",
  "MOCKS_ENABLED": 				false,
  GOOGLE_ANALYTICS_ID: 			    'UA-102407045-3',
  GOOGLE_MAPS_API_KEY: 'AIzaSyD20eeu3zPAii-sfpvHOhSfiiYXnnizguo',
  CLMTLK_WS_BASE_URL: 			    'https://apis.plymouthrock.com/edge/claimstalk-ws/',
  ECLAIMS_ONESITE_WS_BASE_URL:  'https://apis.plymouthrock.com/edge/eclaimsonesite/',
  ECLAIMS_ONESITE_WS_ADMIN_URL: 'https://apis.plymouthrock.com/edge/public/eclaimsonesite/',
  "NOTIFICATION_BASE_URL" : 	  "https://edispatch.plymouthrock.com/eway/route/efnol/notification-web/",
  "EFNOL_SERVICE_BASE_URL": 	  "https://edispatch.plymouthrock.com/eway/route/efnol/fnol-ws-web/efnol/",
  "FNOL_SERVICE_BASE_URL" : 	  "https://edispatch.plymouthrock.com/eway/route/efnol/fnol-ws-web/",
  "PROFILE_SERVICE_BASE_URL" : 	"https://edispatch.plymouthrock.com/eway/route/profile/profile-ws/",
  "DISPATCH_BASE_URL" : 		    "https://edispatch.plymouthrock.com/eway/route/dispatch/dispatch-ui-web/",
  "ESB_URL":                    "https://api.plymouthrock.com/claims/esb4/",
  "CONTACT_DETAILS_URL" : 		  "https://edispatch.plymouthrock.com/",
  "SALVAGE_ASSIGNMENT_URL" :    "https://salvageui.plymouthrock.com/#/salvage/",
  CBSP_BASE_URL:                "https://cbsp.plymouthrock.com/#/",
  "CIRRUS_CLOUD_ACCOUNT"     :   "https://cloudprint.plymouthrock.com",
  TRD_PARTY_LOGIN_MAP: [
    {
      srcApp:"cbsp",
      onSuccessUrl:"https://cbsp.plymouthrock.com/#/"
    }
  ],
  ECLAIMS_ONESITE_WS_LETTER_URL: 'https://apis.plymouthrock.com/edge/public/eclaimsonesite/letters/',
  //CLAIMS_SERVICES_IMPORT_RENTAL: 'https://claims2.prcins.net/claims-services-claimimport/claim/importrental'
};
